import { Text } from '@nike/eds'
import { TrinityButton } from 'components/trinity-button'
import { useMemo } from 'react'
import { BGradeReason, CGradeReason } from 'types'

interface GradeButtonsProps {
  gradeReason: { code: string, reason: string }
  setGradeReason: (gradeReason: { code: string, reason: string }) => void
  grade: string
}

export const GradeButtons = ({ gradeReason, setGradeReason, grade }: GradeButtonsProps) => {
  const gradeButtons = useMemo(() => {
    const reasons = grade === 'B-Grade' ? BGradeReason : CGradeReason
    return Object.keys(reasons).map((key) => {
      const isActive = gradeReason.code === key
      return (
        <TrinityButton key={key} active={isActive} onClick={() => {
          setGradeReason({ code: key, reason: reasons[key] })
        }}>
            {reasons[key]}
        </TrinityButton>
      )
    })
  }, [gradeReason.code, setGradeReason])
  return <>
    <Text font={'display-2'} as={'h1'} className={'mb-6'}>
      {grade} reason
    </Text>
    <div className="grade-button-group-container">
      {gradeButtons}
    </div>
  </>
}
