export interface GradeConfig {
  key: string
  display: string
  qualityCode: string
  needsReason: boolean
}

export const GradeConfigs: Record<string, GradeConfig> = {
  A_GRADE: { key: 'A_GRADE', display: 'A-Grade', qualityCode: '01', needsReason: false },
  HASH: { key: 'HASH', display: 'Hash', qualityCode: '01', needsReason: false },
  B_GRADE: { key: 'B_GRADE', display: 'B-Grade', qualityCode: '02', needsReason: true },
  C_GRADE: { key: 'C_GRADE', display: 'C-Grade', qualityCode: '02', needsReason: true }
}

export const CGradeReason: Record<string, string> = {
  '020': 'DIRTY',
  '090': 'DIRTY SOLES',
  '016': 'DAMAGED',
  '050': 'WET',
  '010': 'DAMAGED BY SECURITY TAG',
  '011': 'SECURITY TAG (PRESENCE)',
  '021': 'DIGITAL – POOR QUALITY',
  '030': 'DISCOLOURED',
  '040': 'BROKEN PRE-PACK',
  '060': 'WRONG CONTENT',
  '070': 'CUSTOMIZED UNITS',
  '081': 'AMERICAN SKU'
}

export const BGradeReason: Record<string, string> = {
  G00: 'GENTLY WORN',
  C00: 'COSMETICALLY FLAWED',
  L00: 'LIKE NEW'
}
