import { Text } from '@nike/eds'
import { TrinityButton } from 'components/trinity-button'
import { GradeConfigs } from 'types'

interface MainGradesProps {
  handleGradeChange: (newGrade: string) => void
  activeGrade: string
  hashable: boolean
}

export const MainGradeButtons = ({ handleGradeChange, activeGrade, hashable }: MainGradesProps) => {
  return <>
    <Text font={'display-2'} as={'h1'} className={'mb-6'}>
      Grade
    </Text>

    <div className={'button-grid'}>
      {hashable
        ? <TrinityButton className={'grid-button full-left background-blue'}
          active={activeGrade === GradeConfigs.HASH.key}
          size={'massive'}
          onClick={() => { handleGradeChange(GradeConfigs.HASH.key) }}>
          Hash
        </TrinityButton>
        : <TrinityButton className={'grid-button full-left background-green'}
          active={activeGrade === GradeConfigs.A_GRADE.key}
          size={'massive'}
          onClick={() => { handleGradeChange(GradeConfigs.A_GRADE.key) }}>
          A-grade
        </TrinityButton>
      }
      <TrinityButton className={'grid-button background-orange'}
        active={activeGrade === GradeConfigs.B_GRADE.key}
        size={'massive'}
        disabled={hashable}
        disabledMessage={hashable ? 'Hash product cannot be B-graded.' : undefined}
        onClick={() => { handleGradeChange(GradeConfigs.B_GRADE.key) }}>
        B-grade
      </TrinityButton>
      <TrinityButton className={'grid-button background-red'}
        active={activeGrade === GradeConfigs.C_GRADE.key}
        size={'massive'}
        onClick={() => { handleGradeChange(GradeConfigs.C_GRADE.key) }}>
        C-grade
      </TrinityButton>
    </div>
  </>
}
