import { Button, Text } from '@nike/eds'
import { UnitDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { Reprint } from 'components/reprint'
import type { LabelType, Unit } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface ReworkProps {
  unitData: Unit
  handleReturn: () => void
  handleContinue: () => void
  printLabelContext: ApiHandlerContext<LabelType>
}

export const Rework = ({ unitData, handleReturn, handleContinue, printLabelContext }: ReworkProps) => {
  const mainPanelContent =
    <div className='flex flex-col flex-grow justify-between'>
      <Text font={'title-1'} as={'h1'}>Rework...</Text>
      <div className="flex justify-between w-100 mt-20">
      <Button className='text-4xl' onClick={handleReturn} variant='secondary'>
          Return
      </Button>
      <Button
        className='text-4xl'
        onClick={handleContinue}
      >
        Continue
      </Button>
      </div>
    </div>

  return <MainPanel
    leftPanelContent={<UnitDetailsPanel unit={unitData} showImage={true} />}
    mainPanelContent={mainPanelContent}
    rightPanelContent={<Reprint unit={unitData} printLabelContext={printLabelContext} />}
  />
}
