import { Button } from '@nike/eds'

interface NumberInputProps {
  value: number
  setValue: (value: number) => void
  minimum?: number
}

const CONTROLS = [1, 5, 10]
const BUTTON_STYLES = ['eds-background--grey-3', 'eds-background--grey-2 eds-border--primary', 'eds-background--grey-1 eds-border--warning eds-color--white']

export const NumberInput = ({ value, setValue, minimum }: NumberInputProps) => {
  const updateValueClamped = (newValue: number) => {
    if (minimum && value + newValue < minimum) {
      setValue(minimum)
    } else {
      setValue(value + newValue)
    }
  }

  return <div className='flex text-4xl'>
    <div className='flex gap-4'>
      {
        CONTROLS.slice().reverse().map((delta, index) => (
          <Button
            key={-delta}
            size='small'
            variant='secondary'
            className={ 'text-4xl flex items-center ' + BUTTON_STYLES.slice().reverse()[index] }
            style={{ fontFamily: 'Arial' }}
            onClick={() => { updateValueClamped(-delta) }}
          >
            &minus;{delta}
          </Button>
        ))
      }
      <p className='flex items-center'><strong>Quantity: {value}</strong></p>
      {
        CONTROLS.map((delta, index) => (
          <Button
            key={delta}
            size='small'
            variant='secondary'
            className={ 'text-4xl flex items-center ' + BUTTON_STYLES[index] }
            style={{ fontFamily: 'Arial' }}
            onClick={() => { setValue(value + delta) }}
          >
            +{delta}
          </Button>
        ))
      }
    </div>
  </div>
}
